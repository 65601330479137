<template>
    <div class='login-bg'>
        <div class='login'>
            <!--<transition name='slide-fade'>-->
                <el-card class="login-box" v-if="show" v-loading="loading" >
                    <el-form @submit.prevent.native="setnumeAgent" >
                        Setare agent
                        <div style="text-align:center; margin-top:40px">
                            <!-- <img src="../assets/logo-pitcar.png" class="img-responsive img-logo" /> -->
                        </div>                                           
                        <el-row class="rand-bearer">
                            <el-col  >
                                <el-input placeholder="Introduceti numele dvs" v-model="numeAgent"></el-input>
                            </el-col>                            
                        </el-row>                                                                        

                        <el-col :span="24" style="margin-top:20px">
                            <el-form-item>
                                <!-- <el-button  style="background-color:black; color:#E6A23C" native-type="submit" class='full-width' > Seteaza </el-button> -->
                                <el-button  style="background-color:black; color:#E6A23C" native-type="submit" class='full-width' > Seteaza </el-button>
                            </el-form-item>
                        </el-col>

                    </el-form>
                </el-card>
            <!--</transition>-->
        </div>
    </div>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import Vue from 'vue'

    export default {
        name: "numeAgent",
        data () {
            return {
                show: false,
                loading: false,
                numeAgent: "",
                isActive:''
            }
        },
        methods: {
            setnumeAgent() {
                settings.set_numeAgent(this.numeAgent)
                
                if(this.numeAgent.trim().length < 1)
                    this.$message({type: 'success',message: 'Agentul a fost sters pentru acest browser!'})
                else
                    this.$message({type: 'success',message: 'Agent setat pentru acest browser!'})
                
                setTimeout(() => {
                    this.$router.push("/");
                    // location.assign("https://programari.anvelopeinstoc.ro/#");  
                    // window.location.reload() 
                }, 5000)             
            }
        },
        mounted: function() {
            // console.log(settings.get_numeAgent());
            // console.log(typeof settings.get_numeAgent());
            if( settings.get_numeAgent() != null) this.numeAgent = settings.get_numeAgent();
            
            this.show   = true;
        }
    };
</script>

<style lang="less" scoped>

    .login-bg{
        background-color: #E6A23C
    }
    .img-logo {
        max-height: 60px;
    }
    .login-box {
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        
        margin: auto;
        width: 500px;
        height:250px;
    }

    .slide-fade-enter-active {
        transition: all 1.3s ease;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateY(250px);
        opacity: 0;
    }
    
    .butoane{
        button
        {
            height: 60px;
            margin: 10px 0px;  

            &:hover{
                background-color:rgba(247, 192, 14, 0.144) !important;   
                border-color: rgba(247, 192, 14, 0.144) !important;
                color:black;
            }
            &:active{
                background-color:black !important;   
                border-color: black !important;
                color:#E6A23C !important;
                font-weight: 700;
                outline:0; 
            }
            &:focus{
                background-color:white;  
                outline: none;
                box-shadow: none;
                border-color:#DCDFE6;
                color:#606266;
            }
        }                      
    }

   
    

</style>

<style lang="less">
     .rand-bearer{
        margin-top:50px;
        .el-input.is-disabled .el-input__inner{
            font-size:30px;
        }
    }
</style>
